var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "GridDraggableColumn",
    {
      key: _vm.column.id,
      attrs: { scope: _vm.scope, index: _vm.columnIndex, column: _vm.column },
      on: { remove: _vm.onRemoveColumn, swap: _vm.onSwapColumns }
    },
    [
      _c(
        "GridColumn",
        {
          attrs: { index: _vm.columnIndex, resizeable: true },
          on: { resize: _vm.onResizeColumn },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function() {
                return [
                  _c("GridHeaderCell", {
                    attrs: {
                      "row-index": _vm.rowsOffset,
                      "column-index": _vm.columnIndex,
                      "column-id": _vm.column.id,
                      label: _vm.column.label,
                      deletable: _vm.column.deletable,
                      "sort-order": _vm.sortOrder,
                      values: _vm.values
                    },
                    on: { sort: _vm.onSortColumn, remove: _vm.onRemoveColumn }
                  })
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _vm._v(" "),
          _vm.isBasicFilter
            ? [
                _vm.column.filter
                  ? _c("GridFilterDataCell", {
                      attrs: {
                        "row-index": _vm.rowsOffset + _vm.basicFiltersOffset,
                        value: _vm.filters[_vm.column.id],
                        component:
                          _vm.dataFilterCellComponents[_vm.column.filter.type],
                        "column-index": _vm.columnIndex,
                        "language-code": _vm.column.language,
                        "column-id": _vm.column.id,
                        filter: _vm.column.filter
                      },
                      on: {
                        "edit-filter-cell": _vm.onEditFilterCell,
                        "filter-value": _vm.onFilterValueChange,
                        "filter-clear": _vm.onFilterValueClear
                      }
                    })
                  : _c("GridTableCell", {
                      attrs: {
                        locked: true,
                        row: _vm.rowsOffset + _vm.basicFiltersOffset,
                        column: _vm.columnIndex
                      }
                    })
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.isColumnInRow
            ? _vm._l(_vm.rows, function(row, rowIndex) {
                return _c("GridDataCell", {
                  key: _vm.rowIds[rowIndex] + "|" + _vm.column.id,
                  attrs: {
                    component: _vm.dataCellComponents[_vm.column.type],
                    data: _vm.getRowData(row, _vm.column),
                    drafts: _vm.drafts,
                    column: _vm.column,
                    "error-messages":
                      _vm.errors[_vm.rowIds[rowIndex] + "/" + _vm.column.id],
                    "row-id": _vm.rowIds[rowIndex],
                    "column-index": _vm.columnIndex,
                    "row-index":
                      _vm.rowsOffset + rowIndex + _vm.basicFiltersOffset + 1,
                    "is-disabled": _vm.disabledRows[_vm.rowIds[rowIndex]],
                    "is-locked": !(_vm.column.editable && _vm.isEditable),
                    "is-copyable":
                      _vm.column.editable &&
                      _vm.isEditable &&
                      !_vm.disabledRows[_vm.rowIds[rowIndex]],
                    "is-selected": _vm.getSelectedRowState(rowIndex)
                  },
                  on: {
                    "cell-value": _vm.onCellValueChange,
                    "edit-cell": _vm.onEditCell
                  }
                })
              })
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }